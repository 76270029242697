html {
  overflow: hidden;
  height: 100vh !important;
  --antd-wave-shadow-color: transparent !important; 
  background: #f0f2f5;
}
@media (max-width: 768px) {
  html{
    overflow: initial;
    height: 100% !important;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*:not(.fa) {
  /* font-family: "SFThonburi" !important; */
  /* font-family: "Nunito","Mitr" !important; */
  font-family: "Mitr", "Nunito" !important;
}
/* @font-face {
  font-family: "SFThonburi";
  src: url("./fonts/SFThonburi-Regular.ttf") format("truetype");
} */
@font-face {
  font-family: "Nunito";
  src: url("./fonts/NunitoSans-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Nunito";
  src: url("./fonts/NunitoSans-Regular.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Nunito";
  src: url("./fonts/NunitoSans-Bold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Nunito";
  src: url("./fonts/NunitoSans-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Nunito";
  src: url("./fonts/NunitoSans-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "Nunito";
  src: url("./fonts/NunitoSans-ExtraBold.ttf");
  font-weight: 900;
}
@font-face {
  font-family: "Mitr";
  src: url("./fonts/Mitr-Light.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Mitr";
  src: url("./fonts/Mitr-Light.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Mitr";
  src: url("./fonts/Mitr-Regular.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Mitr";
  src: url("./fonts/Mitr-Light.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Mitr";
  src: url("./fonts/Mitr-Regular.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "Mitr";
  src: url("./fonts/Mitr-Medium.ttf");
  font-weight: 900;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ant-layout {
  overflow-y: hidden !important;
}
.ant-form-item-control {
  line-height: 20px !important;
}
.ant-drawer-left{
  margin-top: 54px !important;
}
.ant-drawer-right{
  margin-top: 54px !important;
}
/*custom drawer*/
.ant-drawer-header {
  background-color: #f7f7f8 !important;
  height: 64px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 16px 40px !important;
}
.ant-drawer-close {
  font-size: 18px !important;
}
.ant-drawer-title {
  font-size: 25px !important;
  font-weight: 600 !important;
}
.ground_btn {
  position: absolute;
  bottom: 40px;
}

.ant-form-item {
  margin-bottom: 0 !important;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  outline: 0;
  box-shadow: none !important;
}
.ant-input:focus {
  box-shadow: none !important;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: none !important;
}
.ant-btn {
  transition: none !important;
  touch-action: none !important;
  box-shadow: none !important;
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active {
  box-shadow: none !important;
  /* color: rgba(0, 0, 0, 0.65) !important; */
  border-color: #d9d9d9 !important;
  outline: none !important;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
.ant-input:focus,
.ant-input:hover,
.ant-select-selection:hover,
.ant-select-selection:focus,
.ant-select-selection:focus-within {
  /* border-color: #40a9ff !important; */
  border: 1.5px solid #0263E0!important;
box-shadow: 0px 0px 12px rgba(2, 99, 224, 0.2) !important;
}

.fb-button {
  padding: 10px 0;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  transition: all 0.5;
  background-color: #F7F7F8;
  color: #2774FF;
  font-size: 36px;
  cursor: pointer;  
  border: 1px solid #E1E1E1;
  height: 54px;
  
}
@media (max-width: 768px) {
  .fb-button{
    padding: 8px 0;
    height: 42px;
    
  }
  .fb-button svg {
    width: 32px;
    height: 28px;
  }
}

.fb-button i {
  margin: auto;
}
.image-show-preview {
  overflow: hidden;
}
.image-show-preview img {
  object-fit: contain;
  width: 100%;
  height: auto;
  object-position: center;
}
.ant-modal{
  margin-top: 20vh !important;
}
.ant-modal-title {
  font-weight: bold !important;
  font-size: 25px !important;
  color: #000000;
}
.ant-modal-content{
  padding: 32px !important;
}
.ant-modal-header {
  padding: 16px 28px !important;
  background: #fff !important;
}
.add-category .ant-modal-header{
  padding: 16px 0 !important;
}
.ant-modal-body{
  padding: 0 !important;
}
.ant-modal-content .ant-modal-close{
  top: 28px !important;
}
.ant-modal-close {
  top: 37px !important;
}
.ant-modal-header h4 {  
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  text-align: center;
  color: #000000;
}
/* success header without header */
.success-modal .ant-modal-content .ant-modal-header{
  display: none;
}
.success-modal .ant-modal-content .ant-modal-close{
  display: none;
}
.ant-modal-footer {
  border-top: unset !important;
  padding: 30px 0 !important;
  display: flex;
  justify-content: center;
}
.ant-avatar > img {
  object-fit: cover;
}
.ant-calendar-input-wrap {
  height: 48px !important;
  display: flex;
  align-items: center;
}

.ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: 0px !important;
}
.ant-calendar-input-wrap {
  justify-content: center !important;
}
.ant-calendar-range .ant-calendar-input,
.ant-calendar-range .ant-calendar-time-picker-input {
  text-align: center !important;
}

.ant-time-picker {
  width: 100% !important;
}
.ant-calendar-picker {
  width: 100% !important;
}
.ant-input[disabled],
.ant-btn[disabled] {
  /* color: #646464 !important; */
  cursor: inherit !important;
}

.intl-tel-input {
  width: 100% !important;
}
.intl-tel-input input {
  width: 100% !important;
  border: 1px solid #d9d9d9 !important;
  height: 48px !important;
  border-radius: 4px;
  font-size: 16px;
}
.intl-tel-input.allow-dropdown input[type="tel"] {
  outline: none;
  border: none;

  font-size: 16px;
  color: black;
  height: 48px !important;
  width: 100% !important;
  box-shadow: none !important;
}
.text-err {
  text-align: left;
  color: #f43f3f;
  visibility: visible;
  font-size: 11px;
  font-weight: 700;
}
.text-err__hide {
  visibility: hidden;
}
.err-input-phone .intl-tel-input input {
  border: 1px solid #f43f3f !important;
}
.modal-dialog{
  max-width: 660px !important;

}
.modal-content{
  padding: 50px 80px !important;
  
}
.ant-popover-inner{
  border-radius: 8px !important;
}
.ant-popover-content .ant-popover-arrow{
  display: none;
}
/* global for ant style */
@media (max-width: 768px) {
  .modal-dialog {
    margin: 16px !important;
  }
  .modal-content{
    padding: 32px !important;
  }
}
.ant-drawer-content-wrapper {
  box-shadow: initial !important;
  border-top: 1px solid #E1E1E1;
}
/* user profile dropdown css */
.profile-layout{
  padding: 20px !important;
  border-radius: 12px;
  min-width: 340px;
}
.profile-header{
  display: flex; 
  column-gap: 20px;
    align-items: center;
    padding-bottom: 20px; 
}
.profile-info > p{
  margin:0;
}
.profile-name{
  font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 27px;
color: #000000;
max-width: 260px;
}
.profile-role{
  font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
color: #A5A5A5;
}
.profile-email{
  font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
color: #A5A5A5;
}
.ant-menu-item .item-text{
  display: flex;
    align-items: center;
    column-gap: 11px;
}
.ant-popover-inner-content {
  padding: 16px !important;
  border-radius: 20px;  
  margin: auto;
}
.ant-select{
  height: 48px;
}
.ant-select-selection{
  height: 100% !important;
}
.ant-select-selection__rendered{
  height: 100%;
  display: flex !important;
  align-items: center;
}
.form-input .input-with-search {
    width: 100%;
}
.form-input .ant-select-selection__rendered {
    height: 100% !important;
}
.ant-calendar-range .ant-calendar-range-middle{
  display: none;
}
.vs-dm{
  display: block;
}
.vs-ms{
  display: none;
}
@media (max-width: 768px) {
  .vs-dm{
    display: none;
  }
  .vs-ms{
    display: block;
  }
  .ant-modal{
    margin-top: 0vh !important;
    max-width: calc(100vw - 30px) !important;
  }
  .ant-modal-content{
    padding: 16px !important;
  }
  .ant-modal-title p{
    font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 22px;
text-align: center;
  }
  .ant-modal-title h4{
    font-weight: 600;
font-size: 18px;
line-height: 28px;
text-align: center;
  }
  
}